import React, {Component} from 'react';
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import "assets/vendors/style"
import defaultTheme from './themes/defaultTheme';
import AppLocale from '../lngProvider';
import Panel from 'panel/index';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import {getUser, setInitUrl} from '../actions/Auth';
import RTL from 'util/RTL';
import axios from 'util/Api';
import asyncComponent from 'util/asyncComponent';
import Dashboard from "./Dashboard";
import NotifierContainer from "../panel/components/Notifier";
import ColorOption from "./Customizer/ColorOption";
import Home from "./../landingpage";
import '../assets/css/homepage.css'
import {initializeSocket} from "../util/Socket";
import FactorPrint from "../panel/routes/components/factorPrint";

const RestrictedRoute = ({component: Component, token, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            token
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: {from: props.location}
                    }}
                />}
    />;

class App extends Component {

    componentWillMount() {
        initializeSocket(this.props.dispatch);
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        if (this.props.initURL === '') {
            this.props.setInitUrl(this.props.history.location.pathname);
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.token) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + nextProps.token;
        }
        if (nextProps.token && !nextProps.authUser) {
            this.props.getUser()
        }
    }

    render() {
        const {match, location, locale, token, initURL, isDirectionRTL} = this.props;
        const restrictRegex = /(signin)|(signup)|(reset-password)/;
        if (location.pathname === '/') {
            if (token === null) {
                return (<Redirect to={'/home'}/>);
            } else if (initURL === '' || initURL === '/') {
                return (<Redirect to={'/panel'}/>);
            } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
                return (<Redirect to={'/app/sample-page'}/>);
            } else {
                return (<Redirect to={initURL}/>);
            }
        }
        else if (token && location.pathname.match(restrictRegex))
            return (<Redirect to={'/panel'}/>);
        const applyTheme = createMuiTheme(defaultTheme);
        if (isDirectionRTL === false) {
            document.body.classList.remove('rtl');
            applyTheme.direction = 'ltr';
        } else {
            applyTheme.direction = 'rtl';
            document.body.classList.add('rtl')
        }

        const currentAppLocale = AppLocale[locale.locale];
        return (
            <>
                <div className="app-main" style={{overflow: "auto"}}>
                    <MuiThemeProvider theme={applyTheme}>
                            <IntlProvider
                                locale={currentAppLocale.locale}
                                messages={currentAppLocale.messages}>
                                <RTL>
                                    <NotifierContainer/>
                                    <Switch>
                                        <RestrictedRoute path={`${match.url}panel`} token={token}
                                                         component={Panel}/>
                                        <Route path='/signin' component={SignIn}/>
                                        <Route path='/signup' component={SignUp}/>
                                        <Route path='/signup/:referral' component={SignUp}/>

                                        <Route path='/dashboard' component={Dashboard}/>
                                        <Route path='/home' component={Home}/>
                                        <Route path='/reset-password' component={ForgotPassword}/>
                                        <Route path={`/factor_print`} component={FactorPrint}/>
                                        <Route
                                            component={asyncComponent(() => import('./components/Error404'))}/>
                                        <Route path={`/factor_print`} component={FactorPrint}/>

                                    </Switch>
                                </RTL>

                            </IntlProvider>
                            <ColorOption/>
                    </MuiThemeProvider>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({settings, auth}) => {
    const {sideNavColor, locale, isDirectionRTL} = settings;
    const {authUser, token, initURL} = auth;
    return {sideNavColor, token, locale, isDirectionRTL, authUser, initURL}
};

const mapDispatchToProps = (dispatch) => {
    return {
        setInitUrl: (data) => dispatch(setInitUrl(data)),
        getUser: () => dispatch(getUser()),
        dispatch,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

