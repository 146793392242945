import React from 'react';
import {connect} from "react-redux";
import {getCardsList} from "../../../../actions/CreditCardResource";
import IntlMessages from "../../../../util/IntlMessages";
import {TextField, withStyles} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {idSelected, isNumeric} from "../../../../util/Validator";
import {requestWithdraw, switchWithdrawDialog} from "../../../../actions/Wallet";
import {showError} from "../../../components/Notifier";
import {WALLET_WITHDRAW} from "../../../../constants/Units";
import {clearCommas, fa2enNumbers} from "../../../../util/Utilities";
import {Link} from "react-router-dom";
import {findBankByPAN} from "@alibaba-aero/griphook";
import {bankIcons} from "../../../../constants/Banks";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import {Badge} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import {getInstantBanks} from "../../../../util/Socket";
import CloseIcon from '@material-ui/icons/Close';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        left: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    media: {
        height: 0,
        paddingTop: '76.25%', // 16:9
    },
    collapse : {
        display : "block !important",

        '& .MuiCollapse-wrapper' : {
            display: "block !important"

        }
    },
    collapseCard : {
        display: "contents",
        direction: "rtl"
    },
    collapseCardContent : {
        direction: "ltr",
        backgroundColor: "#f0f2ff",
        margin: "0px 10px 10px 10px"
    },
    collapseSubHeader : {
        padding: "10px",
        '& .MuiCardHeader-content': {
            '& .MuiTypography-root' : {
                '& .MuiIconButton-root' : {
                  padding: "5px 12px !important"
                },
                display: "flex",
                justifyContent: "space-between",
                direction: "ltr",
                alignItems: "center"
            }
        }

    },
    guideLinks : {
        display: "flex",
        gap: "2px",
        width: "100%",
        justifyContent: "space-around",
        // flexDirection: "column",
        '& div' : {
            margin: "0px 0px 0px 10px"
        },

    },
    strapButton: {
        minWidth: "60px !important",
        height: "35px",
        borderRadius: "0px !important"
    }

    /* display: flex; */


});

const MAX_WITHDRAW_PART = 1000000000;
const MIN_WITHDRAW_PART = 200000;

class WithdrawDialog extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            openSellDescription: false,
            cards: this.filterVerifiedCards(this.props.cards),
            activeTab: 0,
            maxInstantWithdrawable: 0,
            openCollapsedRules: false,
            openNormalGuide: false,
            inputs: {
                amount : "",
                followUpNo : "",
                selectCard: (this.props.cards.length > 0 ? this.props.cards[0]['id'] : 0),
                selectedCard: (this.props.cards.length > 0 ? this.props.cards[0] : null),
                gateway: 3,
                currencyType: 18,
                instant: 0,
                rulesChecked: true,
            },
            formErrors: {
                amount: '',
                selectCard: "",
                rulesChecked: '',
            }
        }
    }

    componentDidMount() {
        this.setCards();
        getInstantBanks();
    }

    formValidated = () => {
        const {inputs} = this.state;
        const context = this;
        let isValid = true;
        Object.keys(inputs).map(function(key, index) {
            isValid &= context.validateFormValue(key, inputs[key]);
        });
        const {formErrors} = this.state;
        Object.keys(formErrors).map(function(key, index) {
            isValid &= !formErrors[key];
        });
        return isValid;
    };

    validateFormValue = (name, value) => {
        let {formErrors, inputs, cards, maxInstantWithdrawable} = this.state;
        const {currentUser, instantBanks} = this.props;
        switch (name) {
            case 'amount':
                // value = clearCommas(value);
                var pRegex = /^[\u0600-\u06FF\s]+$/;
                var EnRegex = /^[a-zA-Z]+$/;
                value = fa2enNumbers(value);
                value = value.replace(pRegex, '');
                value = value.replace(EnRegex, '');
                if (!isNumeric({input: value}))
                    formErrors.amount = <IntlMessages id="error.content.amount.numeric"/>;
                else if (value < MIN_WITHDRAW_PART / 10)
                    formErrors.amount = <IntlMessages id="error.content.withdraw.amount.min.value"/>;
                else if (value > MAX_WITHDRAW_PART / 10)
                    formErrors.amount = <IntlMessages id="error.content.withdraw.amount.max.value"/>;
                else if (inputs.instant && value > (maxInstantWithdrawable / 10))
                    formErrors.amount = <IntlMessages id="error.content.instant_withdraw.high_amount"/>;
                else if (value > parseInt(currentUser.wallet.balance / 10))
                    formErrors.amount = <IntlMessages id="error.content.withdraw.amount.max.wallet"/>;
                else if (value > parseInt(currentUser.remaining_withdraw / 10))
                    formErrors.amount = <IntlMessages id="error.content.amount.max_withdraw"/>;
                else
                    formErrors.amount = "";
                value = value.toString().length ? parseInt(value) : '';
                break;

            case 'selectCard':
                if (!idSelected({input: value}))
                    formErrors.selectCard = <IntlMessages id="error.content.select_card.null"/>;
                else {
                    formErrors.selectCard = "";
                    let selectedCard = cards.filter((card) => card.id == value)[0];
                    if(selectedCard) {
                        inputs.selectedCard = selectedCard;
                        if(!instantBanks[selectedCard.parent_bank])
                            inputs.instant = 0;
                        else {

                            this.setMaxInstantWithdrawable(selectedCard.parent_bank);
                            if(inputs.amount)
                                this.validateFormValue('amount', inputs.amount);
                        }
                    }

                }
                break;
            case 'instant':
                value = parseInt(value);
                if(inputs.amount) {
                    this.state.inputs.instant = value;
                    this.validateFormValue('amount', inputs.amount);
                }
                break;

        }
        inputs[name] = value;
        this.setState({formErrors, inputs}, () => {
            if(name === 'instant' && value === 1)
                this.setMaxInstantWithdrawable((inputs.selectedCard ? inputs.selectedCard.parent_bank : null));
        });
        return !formErrors[name];
    };

    setMaxInstantWithdrawable = (bankID = null, instantBanks = null) => {
        if(!instantBanks)
            instantBanks = this.props.instantBanks;
        const {cards, inputs} = this.state;
        if(!bankID) {
            let selectedCard = cards.filter((card) => card.id == inputs.selectCard)[0];
            if(selectedCard) {
                bankID = selectedCard.parent_bank;
            }
        }
        const instantBank = instantBanks[bankID];
        if(instantBank) {
            const max = (instantBank.balance - instantBank.freezed - instantBank.in_progress);
            this.state.maxInstantWithdrawable = max;
            this.setState({maxInstantWithdrawable: max});
        }
    }

    handleChange = e => {
        const {name, value, disabled} = e.target;
        if(!disabled)
            this.validateFormValue(name, value);
    };

    setCards() {
        this.props.getCardsList();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {inputs} = this.state;
        this.setState({
            cards: this.filterVerifiedCards(nextProps.cards),
            inputs: inputs
        }, () => {
            if (!inputs.selectCard) {
                this.validateFormValue('selectCard', nextProps.cards[0] ? nextProps.cards[0]['id'] : 0);
            }
        });

        if(JSON.stringify(this.props.instantBanks) !== JSON.stringify(nextProps.instantBanks))
            this.setMaxInstantWithdrawable((inputs.selectedCard ? inputs.selectedCard.parent_bank : null), nextProps.instantBanks);
    }

    filterVerifiedCards(cards) {
        return cards.filter((card) => {
            return card.verified > 10 && card.status > 10
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {amount, selectCard, currencyType, instant} = this.state.inputs;
        if (this.formValidated()) {

            this.props.requestWithdraw({
                amount: clearCommas(amount) * 10,
                card_id: selectCard,
                instant: instant,
                // gateway: gateway,
                currency: currencyType,
            });
            // this.props.switchWithdrawDialog({state: false});
        } else
            showError(WALLET_WITHDRAW, 'form.invalid');

    };

    handleCheck = e => {
        const {name, checked} = e.target;
        const{formErrors, inputs} = this.state;
        inputs[name] = !!checked;
        formErrors.rulesChecked =
            !checked ? "پذیرش قوانین الزامی است." : "";
        this.setState({inputs, formErrors});

    };

    handleRequestCloseWithdrawDialog = () => {
        this.props.switchWithdrawDialog({state: false});
    };

    handleClick = () => {
        const {openSellDescription} = this.state;
        this.setState({openSellDescription: !openSellDescription});

    };

    getMaxWithdrawable = (instant, maxInstantWithdrawable, userBalance, userRemainingWithdraw) => {
        if(instant)
            return Math.min(maxInstantWithdrawable, userBalance, MAX_WITHDRAW_PART, userRemainingWithdraw);
        else
            return Math.min(userBalance, MAX_WITHDRAW_PART, userRemainingWithdraw);
    }

    openInstantWithdrawRules = (e) => {
        const {openNormalGuide} = this.state
        e.preventDefault();
        e.stopPropagation();
        if (openNormalGuide) {
            this.setState({openNormalGuide: false, openInstantGuide: true})
        }
        else
        this.setState({openInstantGuide: true})
    }

    closeInstantWithdrawRules = () => {
        this.setState({openInstantGuide: false})
    }
    openNormaLWithdrawRules = (e) => {
        const {openInstantGuide} = this.state
        e.preventDefault();
        e.stopPropagation();
        if (openInstantGuide) {
            this.setState({openInstantGuide:  false, openNormalGuide: true})

        }
        else
        this.setState({openNormalGuide: true})
    }

    closeNormalWithdrawRules = () => {
        this.setState({openNormalGuide: false})
    }

    handleKeyPress = e => {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }

    render() {
        const {inputs, formErrors, cards, openSellDescription, maxInstantWithdrawable} = this.state;
        const { loadingCards, requestingWithdraw, currentUser, instantBanks, withdrawDialogOpened } = this.props;
        const maxWithdrawable = this.getMaxWithdrawable(inputs.instant, maxInstantWithdrawable, currentUser.wallet.balance, currentUser.remaining_withdraw);
        return (
            <Dialog open={withdrawDialogOpened} onClose={this.handleRequestCloseWithdrawDialog} fullWidth>
                <div className="withdraw-dialog-wrapper">
                    <div className="withdraw-dialog-head">
                        <IconButton className="close-btn"
                                    onClick={this.handleRequestCloseWithdrawDialog}>
                            <CloseIcon/>
                        </IconButton>
                        <div className="heading-title">
                            برداشت وجه
                        </div>
                    </div>
                    <div className="wallet-balance-wrapper">
                        <div className="title-wrapper">
                            <img alt="alt" alt="bag" src={require("../../../../assets/images/V2/wallet-bag.svg")}/>
                            <div className="title">
                                موجودی کیف پول :
                            </div>
                        </div>
                        <div className="value-wrapper">
                            <div className="value">
                                {parseInt(currentUser.wallet.balance / 10).toLocaleString()}
                            </div>
                            <div className="metric">
                                تومان
                            </div>
                        </div>
                    </div>
                    <div className="withdraw-radio-buttons-wrapper">
                        <div className="title">
                            نحوه تسویه:
                        </div>
                        <div className="withdraw-radio-buttons">
                            <RadioGroup
                                aria-label="instant"
                                name="instant"
                                value={inputs.instant}
                                onChange={this.handleChange}
                            >
                                <div className="radios-wrapper">
                                    <div className="wallet-radio-wrapper">
                                        <FormControlLabel className="wallet-radio" value={0} control={<Radio/>}
                                                          label={"عادی"}/>
                                    </div>
                                    <div className="portal-radio-wrapper">
                                        <FormControlLabel  disabled={!instantBanks || !inputs.selectedCard || !instantBanks[inputs.selectedCard.parent_bank] || maxWithdrawable < MIN_WITHDRAW_PART}
                                                           className="portal-radio" value={1} control={<Radio/>}
                                                           label={"آنی"}/>
                                    </div>

                                </div>

                            </RadioGroup>
                        </div>
                    </div>
                    <div className="hints-wrapper">
                        {inputs.instant == 0 ?
                            <div className="hint-content">
                                درخواست‌های برداشت نوع عادی به مقصد تمامی بانک‌ها در اولین سیکل پایای پیش‌رو پس از ثبت درخواست تسویه در روزهای کاری (به علت عدم پردازش پایا در روزهای تعطیل)، به شرح موجود در "قوانین برداشت وجه" انجام خواهند شد.

                            </div>
                            :
                            <div className="hint-content">
                                ثبت درخواست برداشت نوع آنی در لحظه پردازش و به حساب مقصد واریز خواهد شد. در مواردی که ناپایداری در سیستم بانکی و یا اینترنت کشور وجود داشته باشد، درخواست برداشت به کیف پول کاربر عودت گردیده و از طریق تیکت به ایشان اطلاع داده خواهد شد.

                                (برداشت‌های آنی به بانک‌های آینده، سامان، ملی، ملت، تجارت، صادرات، شهر، پاسارگاد، خاورمیانه، کارآفرین، کشاورزی، رسالت، پارسیان، اقتصادنوین انجام می‌شوند، توصیه ما به لحاظ پایداری بیشتر، افتتاح حساب در بانک آینده می‌باشد.)

                            </div>
                        }
                    </div>
                    <div className="inputs-wrapper">
                        <div className="select-card-input">
                            <div className="desc">
                                <IntlMessages id="form.description.select_card_deposit"/>
                            </div>
                            <TextField
                                select
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label="انتخاب کارت بانکی"
                                onChange={this.handleChange}
                                name="selectCard"
                                variant="outlined"
                                fullWidth
                                value={inputs.selectCard}
                                disabled={(!loadingCards && !cards.length)}
                                error={!!formErrors.selectCard}
                                className={formErrors.selectCard ? "error" : null}
                                size="small"
                                style={cards.length ? {textAlign: 'left ', direction: 'ltr'} : {}}
                            >
                                {
                                    loadingCards ?
                                        <MenuItem key={0} value={0} >
                                            {<IntlMessages id="button.loading_card"/>}
                                            <CircularProgress size={24}/>
                                        </MenuItem>
                                        :
                                        (
                                            cards.length === 0 ?
                                                <MenuItem key={0} value={0} style={{textAlign: 'right ', direction: 'rtl'}}>
                                                    {/*{<IntlMessages id="error.field.no_cards_exist"/>}*/}
                                                    <Link to="/panel/creditcard" onClick={this.handleRequestCloseWithdrawDialog}>
                                                        <IntlMessages id="title.message.add-credit-card"/>
                                                    </Link>
                                                </MenuItem> :
                                                cards.map((option) => (
                                                    <MenuItem  key={option.id} value={option.id} style={{textAlign: 'left ', direction: 'ltr'}}>
                                                        <div className="d-inline-flex text-left">
                                                            {findBankByPAN(option.card_number).icon ? <img alt="alt" style={{width: "25px", marginRight: "3px"}}
                                                                                                           src={bankIcons[findBankByPAN(option.card_number).icon.replace('.svg', '')]}/>  : ""}
                                                            <div className="ltr mt-1">
                                                                {option.card_number.substring(0, 4) +
                                                                option.card_number.substring(4, option.card_number.length - 8).replace(/\d/g,"") +
                                                                option.card_number.substring(8, option.card_number.length - 4).replace(/\d/g,"*")
                                                                + option.card_number.substring(option.card_number.length - 4)}</div>
                                                            <div className="mt-1"> ({option.account_number}) </div> {instantBanks && instantBanks[option.parent_bank] ?
                                                            ( <Badge color="info" pill={true} className="d-block mx-1" style={{marginBottom: 0}}><IntlMessages
                                                                id="status.instant.active"/></Badge>) : ''}
                                                        </div>

                                                    </MenuItem>)
                                                ))
                                }
                            </TextField>
                        </div>
                        <div className="amount-max-amount-wrapper">
                            <div className="amount-input">
                                <div className="desc">
                                    <IntlMessages id="form.description.amount_deposit"/>
                                </div>
                                <TextField
                                    id="amount"
                                    label={<IntlMessages id="title.price"/>}
                                    onChange={this.handleChange}
                                    onKeyPress={this.handleKeyPress}
                                    fullWidth
                                    type='number'
                                    variant="outlined"
                                    name= "amount"
                                    autoComplete='off'
                                    error={!!formErrors.amount}
                                    className={formErrors.amount ? "error" : null}
                                    size="small"
                                    value={(inputs.amount)}
                                    InputProps={{
                                        endAdornment: <span className="input-end-adornment">
                                            <IntlMessages id="title.message.toman"/>
                                        </span>,
                                        shrink: true,
                                    }}
                                    style={{textAlign: 'center'}}

                                />
                                <div className="amount-equal">معادل: {((inputs.amount ?? 0) * 10).toLocaleString()} ریال</div>
                                {formErrors.amount && (
                                    <div className="error-box">{formErrors.amount}</div>
                                )}
                            </div>
                            <Link to={'#'} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                inputs.amount = parseInt(maxWithdrawable / 10);
                                this.setState({inputs})
                            }} className="max-amount-wrapper">
                                <div className="title">
                                    حداکثر مبلغ قابل برداشت:
                                </div>
                                <div className="value-wrapper">
                                    <div className="amount-value" >
                                        {parseInt(maxWithdrawable / 10).toLocaleString()}

                                    </div>
                                    <div className="metric">
                                        تومان
                                    </div>
                                </div>

                            </Link>
                        </div>


                    </div>
                    {/*<div className="rules-check-wrapper">*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={*/}
                    {/*            <Checkbox className="rules-check" tabIndex={5}  checked={inputs.rulesChecked}  onChange={this.handleCheck} name="rulesChecked" />*/}
                    {/*        }*/}
                    {/*        label={ <> <a onClick={() => this.handleClick()}  className="rules-desc" >*/}
                    {/*            <span>*/}
                    {/*                قوانین برداشت وجه*/}

                    {/*            </span>*/}

                    {/*        </a>*/}
                    {/*            {" "}*/}
                    {/*            <span>*/}
                    {/*               را می پذریم*/}
                    {/*           </span>*/}
                    {/*        </>}*/}
                    {/*    />*/}
                    {/*    */}
                    {/*</div>*/}
                    {this.state.formErrors.rulesChecked.length > 0 && (
                        <div className="error-box">
                            <img alt="alt" src={require("../../../../assets/images/V2/error.svg")}/>
                            {this.state.formErrors.rulesChecked}
                        </div>

                    )}
                    <div className="buttons-wrapper">
                        <div className="confirm-btn-wrapper">
                            <Button
                                variant="contained"
                                className="confirm-btn"
                                fullWidth
                                disabled={requestingWithdraw}
                                onClick={this.handleSubmit}
                                type="submit"
                            >
                                {requestingWithdraw ? <><IntlMessages id="button.requesting_withdraw"/> <CircularProgress size={24} /></> : <IntlMessages id="button.withdraw"/>}
                            </Button>
                        </div>
                        <div className="cancel-btn-wrapper">
                            <Button
                                variant="contained"
                                onClick={this.handleRequestCloseWithdrawDialog}
                                fullWidth
                                className="cancel-btn"
                            >
                                <IntlMessages id="button.cancel"/>
                            </Button>
                        </div>
                    </div>
                    <div className="rules-wrapper">
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            className="rules-list"
                        >
                            <ListItem button onClick={() => {
                                this.handleClick()
                            }} className="rules-list-item">
                                <ListItemText>
                                    <FormControlLabel
                                        control={
                                            <Checkbox className="rules-check" tabIndex={5}
                                                      checked={inputs.rulesChecked} onChange={this.handleCheck}
                                                      name="rulesChecked"/>
                                        }
                                        label={<> <a  className="rules-desc">
                                <span>
                                    قوانین برداشت وجه

                                </span>

                                        </a>
                                            {" "}
                                            <span>
                                   را می پذیرم
                               </span>
                                        </>}
                                    />
                                </ListItemText>
                                {openSellDescription ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={openSellDescription} timeout="auto" unmountOnExit>
                                <div className="rules-card">
                                    <div className="rule-row">
                                        <IntlMessages id={"contents.withdraw.rules1"}/>
                                    </div>
                                    <div className="rule-row">
                                        <IntlMessages id={"contents.withdraw.rules2"}/>
                                    </div>
                                    <div className="rule-row">
                                         <IntlMessages id={"contents.withdraw.rules3"}/>
                                    </div>
                                    <div className="rule-row">
                                        <IntlMessages id={"contents.withdraw.rules4"}/>
                                    </div>
                                    <div className="rule-row">
                                        <IntlMessages id={"contents.withdraw.rules6"}/>
                                    </div>
                                </div>
                            </Collapse>
                        </List>
                    </div>


                    {/*<div className="card-body p-0">*/}
                    {/*    <form className="row m-0" onSubmit={this.handleSubmit}>*/}
                    {/*        <div className="col-12 col-lg-12 m-auto float-none ">*/}
                    {/*            <div className="jr-fs-lg py-2"><IntlMessages id="form.description.select_card_deposit"/></div>*/}
                    {/*            <TextField*/}
                    {/*                select*/}
                    {/*                onChange={this.handleChange}*/}
                    {/*                name="selectCard"*/}
                    {/*                variant="outlined"*/}
                    {/*                fullWidth*/}
                    {/*                value={inputs.selectCard}*/}
                    {/*                disabled={(!loadingCards && !cards.length)}*/}
                    {/*                error={!!formErrors.selectCard}*/}
                    {/*                className={formErrors.selectCard ? "error" : null}*/}
                    {/*                size="small"*/}
                    {/*                style={cards.length ? {textAlign: 'left ', direction: 'ltr'} : {}}*/}
                    {/*            >*/}
                    {/*                {*/}
                    {/*                    loadingCards ?*/}
                    {/*                        <MenuItem key={0} value={0} >*/}
                    {/*                            {<IntlMessages id="button.loading_card"/>}*/}
                    {/*                            <CircularProgress size={24}/>*/}
                    {/*                        </MenuItem>*/}
                    {/*                        :*/}
                    {/*                        (*/}
                    {/*                            cards.length === 0 ?*/}
                    {/*                                <MenuItem key={0} value={0} style={{textAlign: 'right ', direction: 'rtl'}}>*/}
                    {/*                                    /!*{<IntlMessages id="error.field.no_cards_exist"/>}*!/*/}
                    {/*                                    <Link to="/panel/creditcard" onClick={this.handleRequestCloseWithdrawDialog}>*/}
                    {/*                                        <IntlMessages id="title.message.add-credit-card"/>*/}
                    {/*                                    </Link>*/}
                    {/*                                </MenuItem> :*/}
                    {/*                                cards.map((option) => (*/}
                    {/*                                    <MenuItem  key={option.id} value={option.id} style={{textAlign: 'left ', direction: 'ltr'}}>*/}
                    {/*                                        <div className="d-inline-flex text-left">*/}
                    {/*                                            {findBankByPAN(option.card_number).icon ? <img alt="alt" style={{width: "25px", marginRight: "3px"}}*/}
                    {/*                                                                                           src={bankIcons[findBankByPAN(option.card_number).icon.replace('.svg', '')]}/>  : ""}*/}
                    {/*                                            <div className="ltr mt-1">*/}
                    {/*                                                {option.card_number.substring(0, 4) +*/}
                    {/*                                                option.card_number.substring(4, option.card_number.length - 8).replace(/\d/g,"") +*/}
                    {/*                                                option.card_number.substring(8, option.card_number.length - 4).replace(/\d/g,"*")*/}
                    {/*                                                + option.card_number.substring(option.card_number.length - 4)}</div>*/}
                    {/*                                            <div className="mt-1"> ({option.account_number}) </div> {instantBanks[option.parent_bank] ?*/}
                    {/*                                            ( <Badge color="info" pill={true} className="d-block mx-1" style={{marginBottom: 0}}><IntlMessages*/}
                    {/*                                                id="status.instant.active"/></Badge>) : ''}*/}
                    {/*                                        </div>*/}

                    {/*                                    </MenuItem>)*/}
                    {/*                                ))*/}
                    {/*                }*/}
                    {/*            </TextField>*/}
                    {/*        </div>*/}
                    {/*        <div className="col-12 col-lg-12 m-auto float-none ">*/}
                    {/*            <div className="jr-fs-lg py-3 px-0 align-center">*/}
                    {/*                <div className="d-flex align-center">*/}
                    {/*                    <div className="jr-fs-lg" style={{minWidth: '85px'}}>*/}
                    {/*                        <IntlMessages id="form.description.withdraw_type"/>:*/}
                    {/*                    </div>*/}
                    {/*                    <ButtonGroup disableElevation variant="contained" className='ml-1'>*/}
                    {/*                        <ReactStrapButton className={classes.starpButton}*/}
                    {/*                                          color="primary"*/}
                    {/*                                          name='instant'*/}
                    {/*                                          value={0}*/}
                    {/*                                          outline*/}
                    {/*                                          onClick={this.handleChange}*/}
                    {/*                                          active={inputs.instant === 0}*/}
                    {/*                        >*/}
                    {/*                            عادی*/}
                    {/*                        </ReactStrapButton>*/}
                    {/*                        <ReactStrapButton className={classes.strapButton}*/}
                    {/*                                          color="primary"*/}
                    {/*                                          name='instant'*/}
                    {/*                                          value={1}*/}
                    {/*                                          outline*/}
                    {/*                                          onClick={this.handleChange}*/}
                    {/*                                          disabled={!inputs.selectedCard || !instantBanks[inputs.selectedCard.parent_bank] || maxWithdrawable < MIN_WITHDRAW_PART}*/}
                    {/*                                          active={inputs.instant === 1}*/}
                    {/*                        >*/}
                    {/*                            آنی*/}
                    {/*                        </ReactStrapButton>*/}
                    {/*                    </ButtonGroup>*/}
                    {/*                </div>*/}

                    {/*                <div className={classes.guideLinks}>*/}
                    {/*                    <div>*/}
                    {/*                        <Link to={'#'} onClick={this.openInstantWithdrawRules}>*/}
                    {/*                            /!*<LiveHelpIcon className={"text-primary"}/>*!/*/}
                    {/*                            <IntlMessages id="title.instant_withdraw_guide"/>؟*/}
                    {/*                        </Link>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <Link to={'#'} onClick={this.openNormaLWithdrawRules}>*/}
                    {/*                            /!*<LiveHelpIcon className={"text-primary"}/>*!/*/}
                    {/*                            <IntlMessages id="title.normal_withdraw_guide"/>؟*/}
                    {/*                        </Link>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}



                    {/*            </div>*/}

                    {/*        </div>*/}
                    {/*        <div className="col-12 col-lg-12 m-auto float-none">*/}
                    {/*            <div className="jr-fs-lg py-3"><IntlMessages id="form.description.amount_deposit"/>:*/}
                    {/*                <Link to={'#'} onClick={(e) => {*/}
                    {/*                    e.preventDefault();*/}
                    {/*                    e.stopPropagation();*/}
                    {/*                    inputs.amount = parseInt(maxWithdrawable / 10);*/}
                    {/*                    this.setState({inputs})*/}
                    {/*                }}> حداکثر {parseInt(maxWithdrawable / 10).toLocaleString()}</Link>*/}
                    {/*            </div>*/}
                    {/*            <TextField*/}
                    {/*                id="amount"*/}
                    {/*                label={<IntlMessages id="title.price"/>}*/}
                    {/*                onChange={this.handleChange}*/}
                    {/*                fullWidth*/}
                    {/*                type='number'*/}
                    {/*                variant="outlined"*/}
                    {/*                name= "amount"*/}
                    {/*                autoComplete='off'*/}
                    {/*                error={!!formErrors.amount}*/}
                    {/*                className={formErrors.amount ? "error" : null}*/}
                    {/*                size="small"*/}
                    {/*                value={(inputs.amount)}*/}
                    {/*                InputProps={{*/}
                    {/*                    endAdornment: <span className="text-light-grey mx-2">*/}
                    {/*                        <IntlMessages id="title.message.toman"/>*/}
                    {/*                    </span>,*/}
                    {/*                    shrink: true,*/}
                    {/*                }}*/}
                    {/*                style={{textAlign: 'center'}}*/}

                    {/*            />*/}
                    {/*            <span className="row w-100 small mx-1 px-1 text-primary">معادل: {((inputs.amount ?? 0) * 10).toLocaleString()} ریال</span>*/}
                    {/*            {formErrors.amount && (*/}
                    {/*                <span className="row small text-danger  mr-1">{formErrors.amount}</span>*/}
                    {/*            )}*/}

                    {/*        </div>*/}
                    {/*        <div className="col-12 col-lg-12 m-auto float-none ">*/}
                    {/*            <List*/}
                    {/*                  component="nav"*/}
                    {/*                  aria-labelledby="nested-list-subheader"*/}

                    {/*            >*/}
                    {/*                <ListItem button onClick={() => {*/}
                    {/*                    this.handleClick()*/}
                    {/*                }} className="border-primary" style={{*/}
                    {/*                    borderRadius: "15px 15px 15px 15px",*/}
                    {/*                    border: "2px solid",*/}
                    {/*                    marginTop: "2px"*/}
                    {/*                }}>*/}
                    {/*                    <ListItemText className="jr-fs-lg" primary="قوانین برداشت وجه"/>*/}
                    {/*                    {openSellDescription ? <ExpandLess/> : <ExpandMore/>}*/}
                    {/*                </ListItem>*/}
                    {/*                <Collapse  in={openSellDescription} timeout="auto" unmountOnExit>*/}
                    {/*                    <List component="div" disablePadding>*/}
                    {/*                        <ListItem button style={{backgroundColor: "#ffffff", borderRadius: "5px"}}>*/}
                    {/*                            <ListItemText className="jr-fs-lg">*/}
                    {/*                                <div className="d-inline-flex">*/}
                    {/*                                    <span className="text-secondary font-weight-bold">-</span>*/}
                    {/*                                    <IntlMessages id={"contents.withdraw.rules1"}/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="d-inline-flex">*/}
                    {/*                                    <span className="text-secondary font-weight-bold">-</span>*/}
                    {/*                                    <IntlMessages id={"contents.withdraw.rules2"}/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="d-inline-flex">*/}
                    {/*                                    <span className="text-secondary font-weight-bold">-</span>*/}
                    {/*                                    <IntlMessages id={"contents.withdraw.rules3"}/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="d-inline-flex">*/}
                    {/*                                    <span className="text-secondary font-weight-bold">-</span>*/}
                    {/*                                    <IntlMessages id={"contents.withdraw.rules4"}/>*/}
                    {/*                                </div>*/}
                    {/*                                <div className="d-inline-flex">*/}
                    {/*                                    <span className="text-secondary font-weight-bold">-</span>*/}
                    {/*                                    <IntlMessages id={"contents.withdraw.rules6"}/>*/}
                    {/*                                </div>*/}

                    {/*                            </ListItemText>*/}
                    {/*                        </ListItem>*/}
                    {/*                    </List>*/}
                    {/*                </Collapse>*/}
                    {/*            </List>*/}
                    {/*        </div>*/}
                    {/*        <div className="buttons-wrapper">*/}
                    {/*            <div className="confirm-btn-wrapper">*/}
                    {/*                <Button*/}
                    {/*                    variant="contained"*/}
                    {/*                    className="confirm-btn"*/}
                    {/*                    style={{marginTop: "10px"}}*/}
                    {/*                    fullWidth*/}
                    {/*                    disabled={requestingWithdraw}*/}
                    {/*                    onClick={this.handleSubmit}*/}
                    {/*                    type="submit"*/}
                    {/*                >*/}
                    {/*                    {requestingWithdraw ? <><IntlMessages id="button.requesting_withdraw"/> <CircularProgress size={24} /></> : <IntlMessages id="button.withdraw"/>}*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*            <div className="cancel-btn-wrapper">*/}
                    {/*                <Button*/}
                    {/*                    variant="contained"*/}
                    {/*                    onClick={this.handleRequestCloseWithdrawDialog}*/}
                    {/*                    fullWidth*/}
                    {/*                    className="cancel-btn"*/}
                    {/*                >*/}
                    {/*                  <IntlMessages id="button.cancel"/>*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </form>*/}
                    {/*</div>*/}
                    {/*<div className="w-100">*/}
                    {/*    <Collapse className={classes.collapse} in={openInstantGuide} >*/}
                    {/*        <Divider light={true}  />*/}
                    {/*        <Card  className={classes.collapseCard}>*/}
                    {/*            <CardHeader className={classes.collapseSubHeader} subheader={<>*/}
                    {/*                <div>*/}
                    {/*                    <HelpIcon className="text-primary"/>*/}
                    {/*                    <IntlMessages id="title.instant_withdraw_guide"/>*/}
                    {/*                </div>*/}

                    {/*            <IconButton color={"secondary"} onClick={this.closeInstantWithdrawRules}>*/}
                    {/*                <CloseIcon className="text-secondary"/>*/}
                    {/*            </IconButton></>}/>*/}
                    {/*            <CardContent className={classes.collapseCardContent}>*/}
                    {/*             <p className="mb-0">*/}
                    {/*                 ثبت درخواست برداشت نوع آنی در لحظه پردازش و به حساب مقصد واریز خواهد شد. در مواردی که ناپایداری در سیستم بانکی و یا اینترنت کشور وجود داشته باشد، درخواست برداشت به کیف پول کاربر عودت گردیده و از طریق تیکت به ایشان اطلاع داده خواهد شد.*/}

                    {/*                 (برداشت‌های آنی به بانک‌های آینده، سامان، ملی، پارسیان، اقتصادنوین انجام می‌شوند، توصیه ما به لحاظ پایداری بیشتر، افتتاح حساب در بانک آینده می‌باشد.)*/}

                    {/*             </p>*/}
                    {/*            </CardContent>*/}
                    {/*            /!*<CardActions >*!/*/}
                    {/*            /!*   <Button variant="contained" className="bg-indigo" onClick={this.closeInstantWithdrawRules}>*!/*/}
                    {/*            /!*       <IntlMessages id="button.close"/>*!/*/}
                    {/*            /!*   </Button>*!/*/}
                    {/*            /!*</CardActions>*!/*/}

                    {/*        </Card>*/}

                    {/*    </Collapse>*/}
                    {/*</div>*/}
                    {/*<div className="w-100">*/}
                    {/*    <Collapse className={classes.collapse} in={openNormalGuide} >*/}
                    {/*        <Divider light={true}  />*/}
                    {/*        <Card  className={classes.collapseCard}>*/}
                    {/*            <CardHeader className={classes.collapseSubHeader} subheader={*/}
                    {/*                <>*/}
                    {/*                    <div>*/}
                    {/*                        <HelpIcon className="text-primary"/>*/}
                    {/*                        <IntlMessages id="title.normal_withdraw_guide"/>*/}
                    {/*                    </div>*/}

                    {/*                <IconButton  onClick={this.closeNormalWithdrawRules}>*/}
                    {/*                    <CloseIcon className="text-secondary"/>*/}
                    {/*                </IconButton></>}/>*/}
                    {/*            <CardContent className={classes.collapseCardContent}>*/}
                    {/*                 <p className="mb-0">*/}
                    {/*             درخواست‌های برداشت نوع عادی به مقصد تمامی بانک‌ها در اولین سیکل پایای پیش‌رو پس از ثبت درخواست تسویه در روزهای کاری (به علت عدم پردازش پایا در روزهای تعطیل)، به شرح موجود در "قوانین برداشت وجه" انجام خواهند شد.*/}

                    {/*         </p>*/}
                    {/*            </CardContent>*/}
                    {/*            /!*<CardActions >*!/*/}
                    {/*            /!*    <Button variant="contained" color={"primary"} onClick={this.closeNormalWithdrawRules}>*!/*/}
                    {/*            /!*        <IntlMessages id="button.close"/>*!/*/}
                    {/*            /!*    </Button>*!/*/}
                    {/*            /!*</CardActions>*!/*/}

                    {/*        </Card>*/}

                    {/*    </Collapse>*/}
                    {/*</div>*/}
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = ({creditCard, wallet, auth, settings}) => {
    const{currentUser} = auth;
    const {width} = settings
    const {cards, loadingCards} = creditCard;
    const {requestingWithdraw, withdrawDialogOpened, instantBanks} = wallet;
    return {cards, loadingCards, requestingWithdraw, withdrawDialogOpened, instantBanks, currentUser, width}
};

export default connect(mapStateToProps, {getCardsList, requestWithdraw, switchWithdrawDialog})(withStyles(styles)(WithdrawDialog));

