import React from 'react';
import {connect} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {getUserScores} from "../../../actions";

class UserInfoPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userLevel: Object.keys(props.userScores).length > 0 ? props.userScores.levels.find((item) => this.props.userScores.score >= item.min_score) : "",

        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(JSON.stringify(this.props.userScores) !== JSON.stringify(nextProps.userScores)) {
            let userLevel = nextProps.userScores.levels.find((item) => nextProps.userScores.score >= item.min_score);
            this.setState({userLevel: userLevel})
        }
    }

    componentDidMount() {

        this.props.getUserScores();
    }

  render() {
      const {currentUser} = this.props;
      const {userLevel} = this.state;
    return (
      <div className="user-pop-up-wrapper">
          <div className="menu-wrapper">
              <div className="menu-header">
               <div className="user-name">
                   {currentUser.first_name + " " + currentUser.last_name}
               </div>
              </div>
              <Link className="menu-item" to="/panel/dashboard">
                  خانه
              </Link>
              <Link className="menu-item" to='/panel/setting'>
                  تنظیمات
              </Link>
              <Link className="menu-item" to={currentUser.phone_number_verified < 10 ? '/panel/authorization/mobile' : currentUser.national_card_verified < 10 ? '/panel/authorization/user' : '/panel/authorization/residential'}>
                  احراز هویت (سطح{currentUser.phone_number_verified < 10 ? '1' : currentUser.national_card_verified < 10 ? '2' : '3'})
              </Link>
              <Link className="menu-item" to='/panel/dashboard'>
                  سطح کاربری ({userLevel.fa_title})
              </Link>
              <Button onClick={() => this.props.userSignOut()} className="menu-sign-out-btn">
                  خروج
              </Button>


          </div>
        {/*<div className="user-profile">*/}
        {/*  <img alt="alt" className="user-avatar border-0 size-40 rounded-circle"*/}
        {/*       src='https://via.placeholder.com/150x150'*/}
        {/*       alt="User"/>*/}
        {/*  <div className="user-detail ml-2">*/}
        {/*    <h4 className="user-name mb-0">Chris Harris</h4>*/}
        {/*    <small>Administrator</small>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<span className="jr-link dropdown-item text-muted">*/}
        {/*  <i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>*/}
        {/*  <IntlMessages id="popup.profile"/>*/}
        {/*</span>*/}
        {/*<span className="jr-link dropdown-item text-muted">*/}
        {/*  <i className="zmdi zmdi-settings zmdi-hc-fw mr-1"/>*/}
        {/*  <IntlMessages id="popup.setting"/>*/}
        {/*</span>*/}
        {/*<span className="jr-link dropdown-item text-muted" onClick={() => {*/}
        {/*  this.props.userSignOut()*/}
        {/*}}>*/}
        {/*  <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>*/}
        {/*  <IntlMessages id="popup.logout"/>*/}
        {/*</span>*/}
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
    const {currentUser, userScores} = auth;
    return {currentUser, userScores}
};

export default connect(mapStateToProps, {userSignOut, getUserScores})(UserInfoPopup);


