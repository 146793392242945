import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import IntlMessages from "../../../util/IntlMessages";
import {Link} from "react-router-dom";

class CardHeader extends React.Component {


  onOptionMenuSelect = event => {
    this.setState({menuState: true, anchorEl: event.currentTarget});
  };
  handleRequestClose = () => {
    this.setState({menuState: false});
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      menuState: false,
    }
  }

  render() {
    const {heading, subHeading} = this.props;
    let {styleName} = this.props;
    return (
      <div className={`jr-card-header d-flex align-items-start ${styleName}`}>
        <div className="mr-auto">
          <h3 className="card-heading">{heading}</h3>
          {subHeading && <p className="sub-heading">{subHeading}</p>}
        </div>

        {/*<IconButton className="icon-btn text-dark" onClick={this.onOptionMenuSelect.bind(this)}>*/}
        {/*  /!*<i className="zmdi zmdi-chevron-down"/>*!/*/}
        {/*</IconButton>*/}
        <div className="text-center card-heading">
          <Link to={'../panel/notices'}>
            <span className="text-white">
                 <IntlMessages id="title.all-notices"/>
            </span>

            <IconButton  className="icon-btn  mx-1" >
              {/*<i className="zmdi zmdi-chevron-down"/>*/}
              <KeyboardReturnIcon/>
            </IconButton>
          </Link>
        </div>


        {/*<CardMenu menuState={menuState} anchorEl={anchorEl}*/}
        {/*          handleRequestClose={this.handleRequestClose.bind(this)}/>*/}
      </div>
    )
  }
}

export default CardHeader;
CardHeader.defaultProps = {
  styleName: '',
  subHeading: ''
};

