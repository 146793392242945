import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    INIT_URL,
    PASSWORD_RESET_FAILED,
    PASSWORD_RESET_SUCCESS,
    SENDING_RESET_PASSWORD_REQUEST,
    SET_FORGET_PASSWORD_STEP,
    SET_SIGNIN_STEP,
    SET_SIGNUP_STEP,
    SIGNIN_FAILED,
    SIGNIN_SUCCESS,
    SIGNING_IN,
    SIGNING_UP,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_FAILED,
    SIGNUP_SUCCESS,
    UPDATE_CAPTCHA_VERSION,
    UPDATE_TERMINAL_INFO,
    USER_DATA,
    USER_TOKEN_EXPIRE,
    USER_TOKEN_SET
} from "../constants/ActionTypes";
import axios from 'util/Api'
import {FORGETPASSWORD, LOGIN, REGISTER, SYSTEM} from "../constants/Units";
import {authorizeSocket} from "../util/Socket";

let requestingUserInfo = false;

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = (fields) => {
  return (dispatch) => {
    dispatch({type: SIGNING_UP});
    axios.post('auth/register', fields).then(
        ({data}) => {
          if (data.result) {
            dispatch({type: SIGNUP_SUCCESS, payload: {signupStep: 2, signupVerifyDetails: data.data.verify_details}})
            dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
          } else {
            if(data.data.reload_captcha == 1)
              dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: REGISTER}});
            dispatch({type: SIGNUP_FAILED, payload: {}});
          }
        }).catch(function (error) {
      dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
      dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: REGISTER}});
      dispatch({type: SIGNUP_FAILED, payload: {}});
    });
  }
};

export const userForgetPassword = (fields) => {
  return (dispatch) => {
    dispatch({type: SENDING_RESET_PASSWORD_REQUEST});
    axios.post('auth/reset-password', fields).then(
        ({data}) => {
          if (data.result) {
            dispatch({type: PASSWORD_RESET_SUCCESS, payload: {forgetPasswordStep: 2, forgetPasswordVerifyDetails: data.data.verify_details}})
            dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
          } else {
            if(data.data.reload_captcha == 1)
              dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: FORGETPASSWORD}});
            dispatch({type: PASSWORD_RESET_FAILED, payload: {}});
          }
        }).catch(function (error) {
      dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
      dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: FORGETPASSWORD}});
      dispatch({type: PASSWORD_RESET_FAILED, payload: {}});
    });
  }
};

export const userSignIn = (fields) => {
  return (dispatch) => {
    dispatch({type: SIGNING_IN});
    axios.post('auth/login', fields).then(
        ({data}) => {
          if (data.result) {
            dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
            if(data.status === 'SUCCESSFUL_LOGIN'){
              localStorage.setItem("token", (data.token.access_token));
              localStorage.setItem("userInfo", JSON.stringify(data.user));
              axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
              authorizeSocket(data.token.access_token);
              dispatch({type: USER_DATA, payload: data.user});
              dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
              dispatch({type: SIGNIN_SUCCESS, payload: {}})
            }else {
              dispatch({type: SIGNIN_SUCCESS, payload: {signinStep: 2, signinVerifyDetails: data.data.verify_details}})
            }

          } else {
            if(data.data.reload_captcha == 1)
              dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: LOGIN}});
            dispatch({type: SIGNIN_FAILED, payload: {}});
          }
    }).catch(function (error) {
      dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
      dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: LOGIN}});
      dispatch({type: SIGNIN_FAILED, payload: {}});
    });
  }
};

export const verifyUserSignIn = (fields) => {
  return (dispatch) => {
    dispatch({type: SIGNING_IN});
    axios.post('auth/verify_login', fields).then(
        ({data}) => {
          if (data.result) {
            if(data.status === 'SUCCESSFUL_LOGIN'){
              localStorage.setItem("userInfo", JSON.stringify(data.user));
              axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
              localStorage.setItem("token", (data.token.access_token));
              authorizeSocket(data.token.access_token);
              dispatch({type: USER_DATA, payload: data.user});
              dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
              dispatch({type: SIGNIN_SUCCESS, payload: {signinStep: 1}})
            }else {
              dispatch({type: SIGNIN_SUCCESS, payload: {signinStep: 2, signinVerifyDetails: data.data.verify_details}})
            }

          } else {
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: LOGIN}});
            dispatch({type: SIGNIN_FAILED, payload: {}});
          }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: LOGIN}});
            dispatch({type: SIGNIN_FAILED, payload: {}});
    });
  }
};

export const verifyUserSignUp = (fields) => {
  return (dispatch) => {
    dispatch({type: SIGNING_UP});
    axios.post('auth/verify_register', fields).then(
        ({data}) => {
          if (data.result) {
            localStorage.setItem("userInfo", JSON.stringify(data.user));
            axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
            localStorage.setItem("token", (data.token.access_token));
            authorizeSocket(data.token.access_token);
            dispatch({type: USER_DATA, payload: data.user});
            dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
            dispatch({type: SIGNUP_SUCCESS, payload: {signupStep: 1}})

          } else {
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: REGISTER}});
            dispatch({type: SIGNUP_FAILED, payload: {}});
          }
        }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: REGISTER}});
      dispatch({type: SIGNUP_FAILED, payload: {}});
    });
  }
};

export const verifyUserForgotPassword = (fields) => {
  return (dispatch) => {
    dispatch({type: SENDING_RESET_PASSWORD_REQUEST});
    axios.post('auth/change-password', fields).then(
        ({data}) => {
          if (data.result) {
            localStorage.setItem("userInfo", JSON.stringify(data.user));
            axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
            localStorage.setItem("token", (data.token.access_token));
            authorizeSocket(data.token.access_token);
            dispatch({type: USER_DATA, payload: data.user});
            dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
            dispatch({type: PASSWORD_RESET_SUCCESS, payload: {forgetPasswordStep: 1}})

          } else {
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: FORGETPASSWORD}});
            dispatch({type: PASSWORD_RESET_FAILED, payload: {}});
          }
        }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: FORGETPASSWORD}});
      dispatch({type: PASSWORD_RESET_FAILED, payload: {}});
    });
  }
};

export const resendSignIn = (fields) => {
  return (dispatch) => {
    dispatch({type: SIGNING_IN});
    axios.post('auth/resend_login', fields).then(
        ({data}) => {
          if (data.result) {
            dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
            dispatch({type: SIGNIN_SUCCESS, payload: {signinStep: 2, signinVerifyDetails: data.data.verify_details}})
          } else {
            if(data.data.reload_captcha == 1)
              dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: LOGIN}});
            dispatch({type: SIGNIN_FAILED, payload: {signinStep: 1}});
          }
        }).catch(function (error) {
      dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
      dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: LOGIN}});
      dispatch({type: SIGNIN_FAILED, payload: {}});
    });
  }
};

export const resendSignUp = (fields) => {
  return (dispatch) => {
    dispatch({type: SIGNING_UP});
    axios.post('auth/resend_register', fields).then(
        ({data}) => {
          if (data.result) {
            dispatch({type: SIGNUP_SUCCESS, payload: {signupStep: 2, signupVerifyDetails: data.data.verify_details}})
            dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
          } else {
            if(data.data.reload_captcha == 1)
              dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: REGISTER}});
            dispatch({type: SIGNUP_FAILED, payload: {signupStep: 1}});
          }
        }).catch(function (error) {
      dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
      dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: REGISTER}});
      dispatch({type: SIGNUP_FAILED, payload: {}});
    });
  }
};

export const resendForgetPassword = (fields) => {
  return (dispatch) => {
    dispatch({type: SENDING_RESET_PASSWORD_REQUEST});
    axios.post('auth/resend_forgot', fields).then(
        ({data}) => {
          if (data.result) {
            dispatch({type: PASSWORD_RESET_SUCCESS, payload: {forgetPasswordStep: 2, forgetPasswordVerifyDetails: data.data.verify_details}})
            dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
          } else {
            if(data.data.reload_captcha == 1)
              dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
            dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: FORGETPASSWORD}});
            dispatch({type: PASSWORD_RESET_FAILED, payload: {forgetPasswordStep: 2}});
          }
        }).catch(function (error) {
      dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
      dispatch({type: FETCH_ERROR, payload: {serverError :error.message, unit: FORGETPASSWORD}});
      dispatch({type: PASSWORD_RESET_FAILED, payload: {}});
    });
  }
};

export const getUser = () => {

  return (dispatch) => {
    if(requestingUserInfo) return;
    requestingUserInfo = true;
    dispatch({type: FETCH_START});
    axios.post('auth/me',
    ).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        localStorage.setItem("userInfo", JSON.stringify(data.user));
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: {errors: {serverError : data.error}, unit: SYSTEM}});
      }
      requestingUserInfo = false;
    }).catch(function (error) {
      requestingUserInfo = false;
      if(error.response && error.response.status === 401){
        localStorage.removeItem("token");
        dispatch({type: USER_TOKEN_EXPIRE});
      }else
        dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: SYSTEM}});

    });
  }
};


export const userSignOut = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/logout',
    ).then(({data}) => {
      if (data.result) {
        dispatch({type: FETCH_SUCCESS});
        localStorage.removeItem("token");
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: SIGNOUT_USER_SUCCESS});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    });
  }
};

export const setTerminalInfo = (terminalInfo) => {
  return (dispatch) => {
    dispatch({type: UPDATE_TERMINAL_INFO, payload: {terminal: terminalInfo}});
  }
};

export const setSigninStep = (step) => {
  return (dispatch) => {
    dispatch({type: SET_SIGNIN_STEP, payload: {step: step}});
  }
};

export const updateCaptchaVersion = () => {
  return (dispatch) => {
    dispatch({type: UPDATE_CAPTCHA_VERSION, payload: {}});
  }
};

export const setSignupStep = (step) => {
  return (dispatch) => {
    dispatch({type: SET_SIGNUP_STEP, payload: {step: step}});
  }
};

export const setForgotPasswordStep = (step) => {
  return (dispatch) => {
    dispatch({type: SET_FORGET_PASSWORD_STEP, payload: {step: step}});
  }
};

