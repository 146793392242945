import React, { Suspense } from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from '../constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import Header from "./components/Header/MainHeader";
import Greeting from "./components/Greeting";
// import CoinView from "./components/CoinView";
// import AdvantagesSlider from "./components/AdvantagesSlider";
// import AdvantagesCard from "./components/AdvantagesCard";
// import PriceList from "./components/PriceTable";
// import GuideSlider from "./components/GuideSlider";
// import UserComments from "./components/UserComments";
import Footer from "./components/Footer";
import {MOBILE_MAX_WIDTH} from "../panel/routes/data/constants";

const CoinView = React.lazy(() => import('./components/CoinView'));
const AdvantagesSlider = React.lazy(() => import('./components/AdvantagesSlider'));
const AdvantagesCard = React.lazy(() => import('./components/AdvantagesCard'));
const PriceList = React.lazy(() => import('./components/PriceTable'));
const GuideSlider = React.lazy(() => import('./components/GuideSlider'));
const UserComments = React.lazy(() => import('./components/UserComments'));


class Home extends React.Component {

    componentDidMount() {

    }

    getMobileComponents(isContentFixed) {
        return (
            <main className="app-main-content-wrapper" style={{overflowY: (isContentFixed ? 'hidden' : 'auto')}}>
                <div className="app-main-content" style={{overflowY: (isContentFixed ? 'unset' : 'auto')}}>
                    <div className="landing-section">
                        <Suspense fallback={<div>Loading...</div>}>
                            <AdvantagesSlider/>
                        </Suspense>

                    </div>
                    <div className="landing-section" style={{marginTop: '40px'}}>
                        <Greeting/>
                    </div>
                    {/*<div className="landing-section">*/}
                    {/*    <Suspense fallback={<div>Loading...</div>}>*/}
                    {/*        <CoinView/>*/}
                    {/*    </Suspense>*/}

                    {/*</div>*/}

                    <div className="landing-section">
                        <Suspense fallback={<div>Loading...</div>}>
                            <AdvantagesCard/>
                        </Suspense>

                    </div>
                    <div className="landing-section">
                        <Suspense fallback={<div>Loading...</div>}>
                            <PriceList/>
                        </Suspense>

                    </div>
                    {/*<div className="">*/}
                    {/*    <LatestNews/>*/}
                    {/*</div>*/}
                    {/*<div className="landing-section">*/}
                    {/*    <Suspense fallback={<div>Loading...</div>}>*/}
                    {/*        <GuideSlider/>*/}
                    {/*    </Suspense>*/}

                    {/*</div>*/}
                    <div className="landing-section py-5">
                        <Suspense fallback={<div>Loading...</div>}>
                            <UserComments/>
                        </Suspense>

                    </div>
                    <div className=" px-0">
                        <Footer/>
                    </div>
                </div>
            </main>
        )
    }

    getDesktopComponents(isContentFixed) {
        return (
            <main className="app-main-content-wrapper"
                  style={{overflowY: (isContentFixed ? 'hidden' : 'auto')}}>
                <div className="app-main-content" style={{overflowY: (isContentFixed ? 'unset' : 'auto')}}>

                    <div className="landing-section">
                        <Greeting/>
                    </div>
                    <div className="landing-section">
                        <Suspense fallback={<div>Loading...</div>}>
                            <CoinView/>
                        </Suspense>

                    </div>
                    <div className="landing-section">
                        <Suspense fallback={<div>Loading...</div>}>
                            <AdvantagesSlider/>
                        </Suspense>

                    </div>
                    <div className="landing-section">
                        <Suspense fallback={<div>Loading...</div>}>
                            <AdvantagesCard/>
                        </Suspense>

                    </div>
                    <div className="landing-section">
                        <Suspense fallback={<div>Loading...</div>}>
                            <PriceList/>
                        </Suspense>

                    </div>
                    {/*<div className="">*/}
                    {/*    <LatestNews/>*/}
                    {/*</div>*/}
                    <div className="landing-section">
                        <Suspense fallback={<div>Loading...</div>}>
                            <GuideSlider/>
                        </Suspense>

                    </div>
                    <div className="landing-section py-5">
                        <Suspense fallback={<div>Loading...</div>}>
                            <UserComments/>
                        </Suspense>

                    </div>
                    <div className=" px-0">
                        <Footer/>
                    </div>
                </div>
            </main>
        )
    }

    render() {
        const {drawerType, homeNavigationStyle, isContentFixed, width} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        return (

            <div className={`app-container ${drawerStyle}`}>

                <div className="app-main-container">
                    <div
                        className={`app-header ${homeNavigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        <Header/>
                    </div>
                    {width < MOBILE_MAX_WIDTH ? this.getMobileComponents(isContentFixed) : this.getDesktopComponents(isContentFixed)}
                </div>

            </div>
        );
    }
}


const mapStateToProps = ({settings}) => {
    const {drawerType, homeNavigationStyle, horizontalNavPosition, isContentFixed, width} = settings;
    return {drawerType, homeNavigationStyle, horizontalNavPosition, isContentFixed, width}
};
export default withRouter(connect(mapStateToProps)(Home));