import React from 'react';
// import ContainerHeader from '../components/ContainerHeader/index';
// import IntlMessages from 'util/IntlMessages';
import OurSideNav from "./OurSideNav/index";
import TopNav from "./components/TopNav";
import Header from "./components/Header";


class Dashboard extends React.Component {

    render() {
        return (
            <div className="app-wrapper">
               
                <div

                    className={`app-header app-header-horizontal`}>
                    <Header/>
                    {<TopNav styleName="app-top-header"/>}

                <OurSideNav/>
                </div>

            </div>
        );
    }
}

export default Dashboard; 