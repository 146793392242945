export const notifications = [
  {
    id: 1,
    image: 'https://via.placeholder.com/208x208',
    name: "Domnic Brown",
    time: "6:19 PM",
    message: "There are many variations of passages of...",
    badge: 5
  },
  {
    id: 2,
    image: 'https://via.placeholder.com/150x150',
    name: "John Smith",
    time: "4:18 PM",
    message: "Lorem Ipsum is simply dummy text of the...",
  },
  {
    id: 3,
    image: 'https://via.placeholder.com/150x150',
    name: "John Smith",
    time: "7:10 PM",
    message: "The point of using Lorem Ipsum is that it has a...",
    badge: 8
  },
  {
    id: 4,
    image: 'https://via.placeholder.com/128x128',
    name: "alex dolgove",
    time: "5:10 PM",
    message: "It is a long established fact that a reader will...",
  },
  {
    id: 5,
    image: 'https://via.placeholder.com/150x150',
    name: "Domnic Harris",
    time: "7:35 PM",
    message: "All the Lorem Ipsum generators on the...",
    badge: 3
  }
];