import {
    AVAIL_CURRENCIES_FETCHED,
    BASE_CURRENCIES_FETCHED,
    CANCEL_REQUEST_FAILED,
    CANCEL_REQUEST_FETCHED,
    CHECKOUT_BUY_FAILED,
    CHECKOUT_BUY_REQUESTED,
    CHECKOUT_SELL_FAILED,
    CHECKOUT_SELL_REQUESTED,
    CURRENCIES_FETCHED,
    FETCH_ERROR,
    FETCH_SUCCESS,
    FETCHING_NETWORKS,
    FETCHING_NETWORKS_FAILED,
    FETCHING_ORDER_INFO,
    FETCHING_TRANSFERS_LIST,
    NETWORKS_FETCHED,
    ORDER_INFO_FAILED,
    ORDER_INFO_FETCHED,
    REDRAW_TRANSFERS_LIST,
    REQUESTING_CHECKOUT_BUY,
    REQUESTING_CHECKOUT_SELL,
    REQUESTING_ORDER_CANCEL,
    REQUESTING_SHOPPING_BUY,
    REQUESTING_SHOPPING_SELL,
    SHOPPING_BUY_FAILED,
    SHOPPING_BUY_REQUESTED,
    SHOPPING_SELL_FAILED,
    SHOPPING_SELL_REQUESTED,
    SUPPLIES_FETCHED,
    SWITCH_BUY_DIALOG,
    SWITCH_CANCEL_DIALOG,
    SWITCH_DEPOSIT_CRYPTO_DIALOG,
    SWITCH_SELL_DIALOG,
    SWITCH_WITHDRAW_CRYPTO_DIALOG,
    TRANSFERS_LIST_FETCHED,
    TRANSLATES_FETCHED,
    USE_GATEWAY,
    USER_DATA_UPDATE,
    USER_TOKEN_EXPIRE,
} from "../constants/ActionTypes";
import axios from "../util/Api";
import {CHECKOUT, SHOPPING_BUY, SHOPPING_SELL, TRANSACTIONS_LIST} from "../constants/Units";
import {getUser} from "./Auth";
import {global} from "../store/global";
import {getTransaction, redrawTransactions} from "./Wallet";
import {getLastLiveChat, getTicketMessages} from "./SupportResource";

const resourcePath = '/';


export const whatsup = (transfer_id, transfer_updated_at, transfer_status, ticket_id, ticket_updated_at, ticket_status, chat_id, chat_updated_at, chat_status) => {
    axios.post('whatsup' , {
        transfer_id, transfer_updated_at, transfer_status, ticket_id, ticket_updated_at, ticket_status, chat_id, chat_updated_at, chat_status
        }
    ).then(({data}) => {
        if (data.result) {
            if(data.data.transfer_updated) {
                if(global.selectedOrderID) {
                    global.dispatcher(getTransfer(transfer_id));
                    global.dispatcher(redrawTransfers());
                    // console.log('should request transfer:', transfer_id);
                }
                if(global.selectedTransID) {
                    global.dispatcher(getTransaction(transfer_id));
                    global.dispatcher(redrawTransactions());
                    // console.log('should request transactions:', transfer_id);
                }
            }
            if(data.data.ticket_updated) {
                if((global.selectedTicket && global.selectedTicket.id) ) {
                    global.dispatcher(getTicketMessages({ticket: global.selectedTicket}, false))
                    // console.log('should request ticket:', ticket_id);
                }
            }
            if(data.data.chat_updated) {
                if((global.chatBoxSelectedTicket && global.chatBoxSelectedTicket.id) ) {// Has problem
                    global.dispatcher(getLastLiveChat(0))
                    // console.log('should request chat:', chat_id);
                }
            }

        } else {
            console.log('error on whatsup', data);
        }
    }).catch(function (error) {
        console.log('whatsup cache:', (error.response ? error.response : error));
    });

};

export const getTransfersList = ({type, page, count}) => {
    return (dispatch) => {
        dispatch({type: FETCHING_TRANSFERS_LIST});
        axios.get('transfers/transactions' , {
                params: {
                    // type: type,
                    page: page,
                    count: count
                }
            }
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: TRANSFERS_LIST_FETCHED, payload: {transactions: data.records, totalTransactions: data.count}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: TRANSACTIONS_LIST}});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: {errors: {serverError: error.message}, unit: TRANSACTIONS_LIST}
                });
                dispatch({
                    type: TRANSFERS_LIST_FETCHED,
                    payload: {transactions: []}
                });
            }
        });
    }

};

export const checkoutBuy = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_CHECKOUT_BUY});
        axios.post(resourcePath + 'exchange/buy', props
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'checkout': data.status}, unit: CHECKOUT}});
                dispatch({type: CHECKOUT_BUY_REQUESTED, payload: {selectedOrder: data, gateway: (data.form_data ?? {})}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: CHECKOUT}});
                dispatch({type: CHECKOUT_BUY_FAILED, payload: {selectedOrder: data, gateway: (data.form_data ?? {})}});
            }
            dispatch({type: USER_DATA_UPDATE, payload: data.user})
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: CHECKOUT}});
            dispatch({type: CHECKOUT_BUY_FAILED});
        });
    }
};

export const checkoutSell = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_CHECKOUT_SELL});
        axios.post(resourcePath + 'exchange/sell', props
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'checkout': data.status}, unit: CHECKOUT}});
                dispatch({type: CHECKOUT_SELL_REQUESTED, payload: {selectedOrder: data, gateway: (data.form_data ?? {})}});
                if(data.status === 'SELL_SUCCESS')
                    dispatch(getUser());
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: CHECKOUT}});
                dispatch({type: CHECKOUT_SELL_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: CHECKOUT}});
            dispatch({type: CHECKOUT_SELL_FAILED});
        });
    }
};

export const getTransfer = (id) => {
    return (dispatch) => {
        dispatch({type: FETCHING_ORDER_INFO});
        axios.get(resourcePath + 'transfer/' + id, {}
        ).then(({data}) => {
            if (data.result) {
                dispatch({type: ORDER_INFO_FETCHED, payload: {selectedOrder: data, gateway: (data.form_data ?? {})}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: CHECKOUT}});
                dispatch({type: ORDER_INFO_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: CHECKOUT}});
            dispatch({type: ORDER_INFO_FAILED});
        });
    }
};

export const CancelOrder = (transferID) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_ORDER_CANCEL});
        axios.put(resourcePath + 'transfers/cancel/' + transferID
        ).then(({data}) => {
            if (data.result) {
                // dispatch({type: FETCH_SUCCESS, payload: {messages: {'checkout': data.status}, unit: CHECKOUT}});
                dispatch({type: CANCEL_REQUEST_FETCHED, payload: {order: data.factor}});
                dispatch(getUser());
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: CHECKOUT}});
                dispatch({type: CANCEL_REQUEST_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: CHECKOUT}});
            dispatch({type: ORDER_INFO_FAILED});
        });
    }

};

export const orderBuy = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_SHOPPING_BUY});
        axios.post(resourcePath + 'exchange/factor', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'shopping_buy': data.status}, unit: SHOPPING_BUY}});
                dispatch({type: SHOPPING_BUY_REQUESTED, payload: {selectedOrder: data, gateway: (data.form_data ?? {})}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SHOPPING_BUY}});
                dispatch({type: SHOPPING_BUY_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: SHOPPING_BUY}});
            dispatch({type: SHOPPING_BUY_FAILED});
        });
    }
};

export const orderSell = (props) => {
    return (dispatch) => {
        dispatch({type: REQUESTING_SHOPPING_SELL});
        axios.post(resourcePath + 'exchange/sell_factor', props
        ).then(({data}) => {

            if (data.result) {
                dispatch({type: FETCH_SUCCESS, payload: {messages: {'shopping_sell': data.status}, unit: SHOPPING_SELL}});
                dispatch({type: SHOPPING_SELL_REQUESTED, payload: {selectedOrder: data, gateway: (data.form_data ?? {})}});
            } else {
                dispatch({type: FETCH_ERROR, payload: {errors: data.errors, unit: SHOPPING_SELL}});
                dispatch({type: SHOPPING_SELL_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }else
                dispatch({type: FETCH_ERROR, payload: {errors: {serverError :error.message}, unit: SHOPPING_SELL}});
            dispatch({type: SHOPPING_SELL_FAILED});
        });
    }
};

export const getNetworks = (props) => {
    return (dispatch) => {
        dispatch({type: FETCHING_NETWORKS});
        axios.get(resourcePath + 'networks', props
        ).then(({data}) => {

            if (data.result) {
                // dispatch({type: FETCH_SUCCESS, payload: {messages: {'shopping_buy': data.status}, unit: SHOPPING_BUY}});
                dispatch({type: NETWORKS_FETCHED, payload: {networks: data.networks}});
            } else {
                dispatch({type: FETCHING_NETWORKS_FAILED});
            }
        }).catch(function (error) {
            if(error.response && error.response.status === 401){
                localStorage.removeItem("token");
                dispatch({type: USER_TOKEN_EXPIRE});
            }
            dispatch({type: FETCHING_NETWORKS_FAILED});
        });
    }
};

export const setCurrencies = (currencies) => {
    if(!currencies)
        return;
    return (dispatch) => {
        dispatch({type: CURRENCIES_FETCHED, payload: currencies});
    }
    // customDispatch();

};

export const setAvailCurrencies = (currencies) => {
    if(!currencies)
        return;
    return (dispatch) => {
        dispatch({type: AVAIL_CURRENCIES_FETCHED, payload: currencies});
    }
};

export const setBaseCurrencies = (currencies) => {
    if(!currencies)
        return;
    return (dispatch) => {
        dispatch({type: BASE_CURRENCIES_FETCHED, payload: currencies});
    }
};

export const setTranslates = (translates) => {
    if(!translates)
        return;
    return (dispatch) => {
        dispatch({type: TRANSLATES_FETCHED, payload: {translates: translates}});
    }
};

export const setSupplies = (supplies) => {
    if(!supplies)
        return;
    return (dispatch) => {
        dispatch({type: SUPPLIES_FETCHED, payload: supplies});
    }
    // customDispatch();

};

export const switchBuyDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_BUY_DIALOG, payload: state});
    }
};

export const switchSellDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_SELL_DIALOG, payload: state});
    }
};

export const switchDepositCryptoDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_DEPOSIT_CRYPTO_DIALOG, payload: state});
    }
};
export const switchWithdrawCryptoDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_WITHDRAW_CRYPTO_DIALOG, payload: state});
    }
};

export const switchCancelDialog = (state) => {
    return (dispatch) => {
        dispatch({type: SWITCH_CANCEL_DIALOG, payload: state});
    }
};

export const redrawTransfers = () => {
    return (dispatch) => {
        dispatch({type: REDRAW_TRANSFERS_LIST});
    }
};

export const useCurrencyGateway = () => {
    return (dispatch) => {
        dispatch({type: USE_GATEWAY});
    }
};