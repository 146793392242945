import React, {createRef} from 'react';
import {Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import '../assets/css/formStyle.css';
import Login from "../actions/Login";
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import {withStyles} from "@material-ui/core/styles";
import {isMobile} from "../util/Validator";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    resendSignIn,
    setDarkTheme,
    setSigninStep,
    updateCaptchaVersion,
    userSignIn,
    verifyUserSignIn
} from "../actions";
import {showError} from "../panel/components/Notifier";
import {LOGIN} from "../constants/Units";
import {fa2enNumbers} from "../util/Utilities";
import BrowserAlert from "./components/BrowserAlert";
import {BRANCH_ESSENTIALS, MOBILE_MAX_WIDTH} from "../panel/routes/data/constants";
import ImageStorage from "../assets/ImageStorage";
import {DOMAIN} from "../constants/Path";

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        color: grey[50],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


const emailRegex = RegExp(
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
);
// const phoneRegex = RegExp(/^[+][0-9 ]{9,20}$/);

// let history = useHistory();

class SignIn extends React.Component {

    constructor(props) {
        super(props);
        this.timer = 0;
        this.updateRecaptcha();
    }

    state = {
        userInfo: {
            email: '',
            phone_number: '',
            password: '',

        },
        formErrors: {
            captcha: "",
            email: "",
            password: ""
        },
        refs: {
            email: createRef(),
            code: createRef(),
            captcha: createRef()
        },
        captcha: '',
        verifyCode: '',
        error: {},
        loading: false,
        success: false,
        activeStep: 0,
        formValid: {},
        formToken: '',
        userInputName: 'emailOrPhone',
        showPassword: false,
        time: {},
        seconds: "",
        resendLeftSecs: 120,

    };

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    handleNext = (e) => {
        e.preventDefault();
        const {signinStep} = this.props;

        if (signinStep === 1) {
            const {userInfo, userInputName, captcha, formErrors} = this.state;
            if (!!formErrors.password || !!formErrors[userInputName] || captcha.length !== 6) {
                showError(LOGIN, 'form.invalid');
                return;
            }

            let fields = {password: userInfo.password, captcha: captcha, terminal_id : BRANCH_ESSENTIALS.TERMINAL_ID, terminal_token : BRANCH_ESSENTIALS.TERMINAL_TOKEN};
            fields[userInputName] = userInfo[userInputName];
            this.props.userSignIn(fields);
        } else if (signinStep === 2) {
            if (this.state.verifyCode.length < 5) {
                showError(LOGIN, 'form.invalid');
                return;
            }
            this.props.verifyUserSignIn({token: this.props.signinVerifyDetails.token, code: this.state.verifyCode});

        }
    };

    componentWillUnmount() {
        this.stopTimer();
    }

    handleBack = () => {
        const {signinStep} = this.props;
        this.props.setSigninStep(signinStep - 1);
        this.setState({
            email: "",
            phone_number: "",
            password: "",
        });
    };

    handleSubmit = () => {
        this.setState({submitted: true}, () => {
            setTimeout(() => this.setState({submitted: false}), 2000);
        });
    };

    handleChange = e => {
        // localStorage.clear();
        e.preventDefault();
        let {name, value} = e.target;
        let formErrors = {...this.state.formErrors};
        value = fa2enNumbers(value);
        switch (name) {
            case this.state.userInputName:
                formErrors.email = '';
                if (emailRegex.test(value)) {
                    this.state.userInputName = 'email';
                    this.state.userInfo.email = value;
                } else if (isMobile({input: value})) {
                    this.state.userInputName = 'phone_number';
                    this.state.userInfo.phone_number = value;
                } else {
                    formErrors.email = <IntlMessages id="error.content.email_mobile.invalid"/>;
                    this.state.userInputName = 'emailOrPhone';
                    this.state.userInfo.phone_number = '';
                    this.state.userInfo.email = '';
                }
                break;
            case "password":
                formErrors.password =
                    value.length < 8 ? <IntlMessages id="error.content.password.length"/> : "";
                this.state.userInfo.password = value;
                break;
            case "verifyCode":
                formErrors.verifyCode =
                    value.toString().length < 5 ? <IntlMessages id="error.content.auth_code.invalid"/> : "";
                break;
            default:
                break;
        }
        this.setState({formErrors, [name]: value},);
    };

    handleResendCode = (e) => {
        e.preventDefault();
        this.props.resendSignIn({token: this.props.signinVerifyDetails.token, terminal_token: BRANCH_ESSENTIALS.TERMINAL_TOKEN, terminal_id: BRANCH_ESSENTIALS.TERMINAL_ID});
    };

    startTimer() {
        const {signinVerifyDetails} = this.props;
        const now = Date.now() / 1000;
        if (signinVerifyDetails && signinVerifyDetails.next_request > now && !this.timerStarted) {
            this.timer = setInterval(() => {
                const resendLeftSecs = this.props.signinVerifyDetails.next_request - parseInt(Date.now() / 1000);
                this.setState({resendLeftSecs: Math.max(resendLeftSecs, 0)});
                if (resendLeftSecs <= 0)
                    this.stopTimer();
            }, 1000);
            this.timerStarted = true;
        }

    }

    stopTimer() {
        if (this.timerStarted && this.timer) {
            clearInterval(this.timer);
            this.timerStarted = false;
        }
    }

    getVerifyCode() {
        const {signinVerifyDetails} = this.props;
        return <>
            <div className="hint-box">
                <div className="hint-row">
                    {signinVerifyDetails.field == "mobile" ?
                        <>لطفا کد ارسال شده به شماره <div style={{direction: 'ltr', display: 'inline-block'}}>{signinVerifyDetails.mobile}</div> را وارد نمایید</>
                        : signinVerifyDetails.field == "email" ?
                            <>لطفا کد ارسال شده به ایمیل <div style={{direction: 'ltr', display: 'inline-block'}}>{signinVerifyDetails.email}</div> را وارد نمایید</>
                            : ""
                    }
                </div>
                <div className="gradient-bottom"/>

                {/*{<IntlMessages id="form.description.enter_verification_code"/>}*/}
            </div>
            <div className="validity-wrapper">
                <div className="validity-desc">
                    اعتبار تا :
                </div>
                <span className="validity-secondsl">
                {" " + signinVerifyDetails.code_validity / 60 + " دقیقه "}
            </span>
            </div>
            <TextField
                type="number"
                id="verifyCode"
                name="verifyCode"
                autoComplete='off'
                label={<IntlMessages id="appModule.verifycode"/>}
                fullWidth
                inputRef={this.state.refs.code}
                autoFocus={true}
                margin="normal"
                inputProps={{ tabIndex: "1" ,  autoComplete: 'off'}}
                className="mt-1"
                onChange={this.handleChange}
                error={this.state.error.verifyCode}
                variant="outlined"
                size="small"
            />

            {this.state.resendLeftSecs === 0 ?
                <div className="timer-wrapper">
                    <Link to="" onClick={this.handleResendCode}>
                        ارسال مجدد کد تایید
                    </Link>
                </div>

                :
                <div className="timer-wrapper">
                    {this.state.resendLeftSecs === 0 ? "ارسال مجدد کد تایید" : "ارسال مجدد تا " + this.state.resendLeftSecs + " ثانیه"}
                </div>
            }
            {signinVerifyDetails.field == "email" ?
                <div className="hint-box">
                    <div className="hint-row">
                        لطفا پوشه اسپم ایمیل خود را نیز بررسی نمائید.
                    </div>
                    <div className="gradient-bottom"/>
                </div>
                : ""
            }


            <TextField
                className="d-none"
                type="hidden"
                id="verifyCode"
                name="verifyCode"
                value={this.state.formToken}

            />

        </>
    }

    updateRecaptcha = () => {
        this.props.updateCaptchaVersion();
        if(this.state.refs.captcha.current ) {
            this.state.refs.captcha.current.focus();
        }
    }

    getUserInfo() {
        return (
            <>
                <BrowserAlert/>
                <div>
                    <div className="sign-input-labels">
                        ایمیل یا شماره همراه:
                    </div>
                    <TextField
                        id="emailOrPhoneNo"
                        // label={<IntlMessages id="appModule.email-or-mobile"/>}
                        // value={this.state.userInfo.email}
                        fullWidth
                        inputProps={{ tabIndex: "1" }}
                        autoFocus={true}
                        inputRef={this.state.refs.email}
                        margin="normal"
                        className={this.state.formErrors.email ? "error" : null}
                        placeholder="Email or PhoneNumber"
                        type="text"
                        name={this.state.userInputName}
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.email}
                        variant="outlined"
                        size="small"
                    />
                    {this.state.formErrors.email && (
                        <div className="error-box">
                            <img alt="alt" src={require("../assets/images/V2/error.svg")}/>
                            {this.state.formErrors.email}
                        </div>
                    )}
                </div>
                <div>
                    <div className="sign-input-labels">
                        رمز عبور:
                    </div>
                    <TextField
                        id="password"
                        // value={this.state.userInfo.password}
                        // label={<IntlMessages id="appModule.password"/>}
                        fullWidth
                        inputProps={{ tabIndex: "2" }}
                        margin="normal"
                        className={this.state.formErrors.password ? "error" : null}
                        placeholder="Password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        name="password"
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.password}
                        // value={this.state.password}
                        variant="outlined"
                        size="small"
                        InputProps={{

                            startAdornment:
                                <IconButton
                                    tabIndex={-1}
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                    className="input-start-adornment"
                                >
                                    {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>


                        }}

                    />
                    {this.state.formErrors.password && (
                        <div className="error-box">
                            <img alt="alt" src={require("../assets/images/V2/error.svg")}/>
                            {this.state.formErrors.password}
                        </div>

                    )}
                </div>
                <div className="forgot-password-wrapper">
                    <Link to="/reset-password">
                        رمز خود را فراموش کرده اید؟
                    </Link>
                </div>
                <div className="captcha-input-wrapper">
                    <div className='captcha-wrapper'>
                        <IconButton
                            tabIndex={-1}
                            onClick={this.updateRecaptcha}
                        >
                            <img alt="alt" src={require("../assets/images/V2/refresh.svg")}/>
                        </IconButton>
                        <img src={'/core/api/captcha/login?v=' + this.props.captchaVersion}
                             alt='Forgot Password Captcha' style={{padding: '8px 8px 0px 8px'}}/>
                    </div>
                    <div>
                        <div className="sign-input-labels">
                            کد امنیتی:
                        </div>
                        <TextField
                            id="captcha"
                            // label={<IntlMessages id="appModule.captcha_input"/>}
                            // value={this.state.userInfo.email}
                            fullWidth
                            inputProps={{ tabIndex: "3" }}
                            inputRef={this.state.refs.captcha}
                            autoComplete='off'
                            margin="normal"
                            className={this.state.formErrors.captcha.length > 0 ? "error" : null}
                            placeholder=""
                            type="number"
                            name='captcha'
                            noValidate
                            onChange={this.handleChange}
                            error={this.state.error.captcha}
                            variant="outlined"
                            size="small"
                        />
                        {this.state.formErrors.captcha.length > 0 && (
                            <div className="error-box">
                                <img alt="alt" src={require("../assets/images/V2/error.svg")}/>
                                {this.state.formErrors.captcha}
                            </div>
                        )}
                    </div>
                </div>

            </>
        )


    }

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 1:
                this.stopTimer();
                return this.getUserInfo();
            case 2:
                this.startTimer();
                return this.getVerifyCode();
            default:
                return <IntlMessages id="appModule.loginSuccessfully"/>;
        }
    }

    changeDarkMode = (setDark) => {
        const {darkTheme} = this.props;
        if(setDark !== darkTheme) {
            this.props.setDarkTheme();
            const body = document.body.classList;
            body.toggle('dark-theme')
        }
    }

    render() {
        const {classes, signingIn, signinStep, darkTheme, width} = this.props;

        return (

                <div className="w-100 h-100"> 
                    <div className="app-login-main-content h-100">
                        <div className="app-logo-content  align-items-center">
                            <div className="app-logo-content-header">
                                <div onClick={(e) => darkTheme ? this.changeDarkMode(false) : this.changeDarkMode(true)} className="change-theme-wrapper">
                                    {ImageStorage.header.darkMode(darkTheme, 'mode-btn')}
                                </div>
                                <Link className="logo-wrapper" to="/home" title="Lock">
                                    {darkTheme ?<img alt="alt" src={require("../assets/images/logo/Logo.svg")}/> : <img alt="alt" src={require("../assets/images/logo/LogoLight.svg")}/> }
                                </Link>

                            </div>

                            <img className="lock-img" src={require("assets/images/V2/signlock.svg")}

                                 alt="Lock" title="Lock"/>
                            <div className="hint-box">
                                <div className="hint-row">
                                    درصورتیکه قبلا ثبت نام نموده اید و برای نخستین بار است که وارد پنل جدید می شوید, لطفا از طریق
                                    منوی <Link to="/reset-password">فراموشی رمزعبور</Link> اقدام به بازیابی کلمه عبور حسابتان کنید.

                                </div>
                                <div className="gradient-bottom"/>
                                    </div>
                        </div>
                        <div className="app-login-content">
                            <div className="app-login-content-inner">
                                {width > MOBILE_MAX_WIDTH ? "" :
                                    <div className="mobile-logo-wrapper">
                                        <img alt="alt" src={darkTheme ? require("../assets/images/logo/Logo.svg") : require("../assets/images/logo/LogoLight.svg") } />
                                        <div onClick={(e) => darkTheme ? this.changeDarkMode(false) : this.changeDarkMode(true)} className="change-theme-wrapper">
                                            {ImageStorage.header.darkMode(darkTheme, 'mode-btn')}
                                        </div>
                                    </div>
                                }

                                <div className="app-login-header">
                                    <div className="sign-title">
                                        <IntlMessages id="home.header.signin"/>
                                    </div>
                                    <div className="sign-desc">
                                        <div>
                                            ثبت نام نکرده اید؟
                                        </div>
                                        <div>
                                            <Link to="/signup" title="Reset Password">
                                                ثبت نام کنید
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="login-form" >
                                    <form>
                                        <fieldset>
                                            {this.getStepContent(signinStep)}
                                            {signinStep === 1 ?
                                                <div className="confirm-btn-wrapper">
                                                    <Button
                                                        variant="contained"
                                                        className="confirm-btn"
                                                        disabled={signingIn}
                                                        onClick={this.handleNext}
                                                         fullWidth
                                                        type="submit"
                                                    >
                                                        {signinStep === 1 ?
                                                            <IntlMessages id="appModule.signin"/> : "تایید"}
                                                    </Button>
                                                    {signingIn &&
                                                    <CircularProgress size={24}
                                                                      className={classes.buttonProgress}/>}
                                                </div>
                                                :
                                                <div className="buttons-wrapper">
                                                    <div className="confirm-btn-wrapper">
                                                        <Button
                                                            variant="contained"
                                                            className="confirm-btn"
                                                            disabled={signingIn}
                                                            onClick={this.handleNext}
                                                            fullWidth
                                                            type="submit"
                                                        >
                                                            {signinStep === 1 ?
                                                                <IntlMessages id="appModule.signin"/> : "تایید و ورود"}
                                                        </Button>
                                                        {signingIn &&
                                                        <CircularProgress size={24}
                                                                          className={classes.buttonProgress}/>}
                                                    </div>
                                                    <div className="cancel-btn-wrapper">
                                                        <Button
                                                            onClick={this.handleBack}
                                                            className="cancel-btn"
                                                            fullWidth
                                                        >
                                                            <IntlMessages id="appModule.back"/>
                                                        </Button>
                                                    </div>
                                                </div>
                                            }

                                        </fieldset>
                                    </form>
                                </div>
                                <div className="home-page">
                                    <Link to="./home">
                                        برو به صفحه‌ی اصلی
                                    </Link>
                                </div>

                                {width > MOBILE_MAX_WIDTH ? "" :
                                    <div className="mobile-hint-wrapper">
                                        <div className="mobile-hint-logo-wrapper">
                                            <img src={require("assets/images/V2/signlock.svg")}

                                                 alt="Lock" title="Lock"/>
                                        </div>
                                        <div className="hint-box">
                                            <div className="hint-row">
                                                درصورتیکه قبلا ثبت نام نموده اید و برای نخستین بار است که وارد پنل جدید می شوید, لطفا از طریق
                                                منوی <Link to="/reset-password">فراموشی رمزعبور</Link> اقدام به بازیابی کلمه عبور حسابتان کنید.

                                            </div>
                                            <div className="gradient-bottom"/>
                                        </div>

                                    </div>
                                }

                            </div>

                        </div>

                    </div>
                </div>

        );
    }
}

const mapStateToProps = ({auth, settings}) => {
    const {darkTheme, width} = settings;
    const {signingIn, tokenReceiveTime, signinStep, signinVerifyDetails, captchaVersion} = auth;
    return {signingIn, tokenReceiveTime, signinStep, signinVerifyDetails, captchaVersion, darkTheme, width};
};

export default compose(
    connect(
        mapStateToProps,
        {setDarkTheme, userSignIn, verifyUserSignIn, setSigninStep, updateCaptchaVersion, resendSignIn},
    ),
    withStyles(styles)
)(SignIn)

